.new-quote-info-accordion {
	width: 100%;
	&-top {
		width: 100%;
	}
	&-item {
		display: flex;
		width: 45%;
		font-weight: 500;
		font-size: 16px;
		&-title {
			font-weight: 700;
			font-size: 16px;
		}
	}
	&-toggle-label {
		font-weight: 400;
		color: rgb(236, 53, 20);
		font-size: 12px;
		text-align: right;
		&--ml-auto {
			margin-left: auto;
		}
	}
	&-summary {
		display: flex;
		width: 100%;
		font-size: 16px;

		&-item {
			width: 53%;
		}
		&-weight {
			width: 45%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
	&-details {
		display: flex;
		flex-direction: column;
		width: 100%;
		&-item {
			width: 100%;
			font-size: 16px;
			margin: 5px;
		}
	}
	&-references {
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: wrap;
		&-item {
			justify-content: space-around;
			justify-items: stretch;
			border: 2px solid #c9cfe4 !important;
		}
	}
	.MuiIconButton-label {
		color: rgb(236, 53, 20);
	}
	.MuiAccordionSummary-root {
		padding: 0;
	}
}
