.new-user-form {
	margin: -16px -24px;
	@media (max-width: 768px) {
		margin: -16px;
	}
}

.new-user-title {
	text-align: start;
	margin: 0;
	font-family: 'Open Sans';
	font-size: 34px;
	font-weight: 700;
	color: #2c2c2c;
}

.new-user-header {
	width: 100%;
	padding: 24px;
	background: #f4f6f6;
}

.new-user-form-container {
	padding: 40px 24px;
	display: grid;
	grid-auto-flow: row;
	row-gap: 30px;
}

.new-user-form-row {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 30px;

	@media (max-width: 767px) {
		grid-auto-flow: row;
		grid-template-columns: none;
		column-gap: 0;
		row-gap: 30px;
	}
}
