.shipment-references-slider {
	width: 340px;
	margin-bottom: 1em;
	@media (min-width: 768px) {
		display: none;
	}
}

.shipment-references-slider-container {
	width: 90%;
	border: 1px solid #c9cfe4;
	border-radius: 4px;
	padding: 15px;
	background-color: #fafbfc;
}

.shipment-references-slider-title {
	display: flex;
	gap: 5px;
	align-items: center;
	margin: 0 0 8px 0;
	font-weight: 500;
	font-size: 14px;
	span {
		font-weight: 700;
		font-size: 14px;
	}
}

#shipment-references-slider-title-id {
	margin: 0 0 16px 0;
}

.shipment-references-slider-delete {
	display: flex;
	justify-content: flex-end;
}

.shipment-references-slider-delete-title {
	font-weight: 500;
	font-size: 12px;
	color: #a1a1a1;
	margin: 5px 0 0 4px;
}
