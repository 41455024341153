.shipment-carrier-information {
	&-container {
		display: grid;
		grid-auto-flow: row;
		row-gap: 16px;

		@media (min-width: 768px) {
			grid-auto-flow: column;
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&-subtitle {
		color: #ec3514;
		font-weight: 700;
		font-size: 14px;
		margin: 5px 0 20px 0;

		@media (min-width: 768px) {
			font-weight: 600;
			font-size: 15px;
		}
	}
	&-logo-and-name {
		display: flex;
		column-gap: 8px;
		margin-bottom: 8px;

		@media (min-width: 768px) {
			column-gap: 12px;
		}
	}

	&-name {
		color: #2c2c2c;
		font-weight: 600;
		font-size: 18px;
	}

	&-data {
		@media (min-width: 768px) {
			padding: 0 16px;
		}
	}
}

.shipment-carrier-rates-mobile {
	@media (min-width: 768px) {
		display: none;
	}
}

.shipment-carrier-rates-web {
	@media (max-width: 767px) {
		display: none;
	}
}

.shipment-carrier-rates {
	padding: 0 16px;

	@media (max-width: 767px) {
		border: 1px solid #c9cfe4;
		border-radius: 4px;
		padding: 15px;
		background-color: #fafbfc;
	}
	&-titles {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: repeat(2, 1fr);
	}
	&-title {
		font-weight: 700;
		font-size: 16px;
		margin: 0;

		@media (min-width: 768px) {
			font-size: 14px;
		}
	}
	&-row {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: repeat(1, 50%) repeat(1, 25%) repeat(1, 25%);
	}
}

.shipment-carrier-rate-item {
	display: grid;
	grid-auto-flow: column;
}

.shipment-rate-title {
	width: 70%;
	font-family: 'Open Sans';
	font-weight: 400;
	font-size: 14px;
	color: #2c2c2c;
	margin-bottom: 0;

	@media (min-width: 768px) {
		width: 90%;
	}
}

.shipment-rate-value {
	margin: 2px 0;
	font-weight: 400;
	font-size: 14px;
	text-align: end;

	@media (min-width: 768px) {
		text-align: start;
		align-self: end;
	}

	span {
		font-weight: 700;
		font-size: 14px;
	}
}

.shipment-rate-title-total {
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 0;

	@media (min-width: 768px) {
		font-size: 14px;
	}
}

#shipment-carrier-rate-title {
	justify-self: end;

	@media (min-width: 768px) {
		justify-self: start;
	}
}

.shipment-carrier-rates-items-separator {
	margin-top: 10px;
}

#shipment-rate-insurance {
	width: 100%;
	span {
		font-weight: 700;
	}
}
