.modalDialog .MuiDialog-root {
	z-index: 10000;
}

.modalDialog .MuiDialog-paper {
	background: white;
	width: 70%;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 1%;
	border-radius: 5px;
	border-bottom: 6px solid #007a8a !important;
	padding: 10px 50px;
	overflow: hidden;
	@media screen and (max-height: 600px) {
		margin-top: 10px;
		padding: 20px 50px;
	}
}

.modalDialog .MuiFormControl-root {
	margin: 10px 0px;
}

.modalDialog .MuiGrid-root {
	padding-right: 0px;
	padding-top: 0px;
}

.modalDialog form {
	width: 99.9%;
	max-width: 99.9%;
}

.modalDialog .tableContainer {
	display: block;
	max-height: 64vh;
	overflow-y: auto;
	@media screen and (max-height: 800px) {
		max-height: 50vh;
	}
	@media screen and (max-height: 600px) {
		max-height: 45vh;
	}
}

#simple-dialog-title {
	@media screen and (max-height: 800px) {
		padding-bottom: 8px;
	}
}
