#okta-sign-in {
	&.auth-container.main-container {
		//background-color: red;
		border: 0;
		margin: 0;

		.auth-header {
			padding: 15px 0 0 0;
		}

		.auth-org-logo {
			height: 42px;
			width: auto;
			max-height: unset;
			max-width: unset;
			margin: 0 0 30px 0;
			padding: unset;
		}

		.o-form-head {
			text-align: left;
		}

		.o-form-control.okta-form-input-field.o-form-has-errors {
			//border-color: mat.get-color-from-palette(palettes.$red-palette, 500);
			border-color: #d93934;
		}

		.o-form-explain.o-form-input-error {
			margin-left: 5px;
		}

		.auth-content {
			padding-left: 0px;
			padding-right: 0px;
		}

		h2 {
			//@include mat.typography-level(themes.$tms-typography, 'title');
			font:
				600 0.875rem / 1.125rem Roboto,
				'Helvetica Neue',
				sans-serif;
			letter-spacing: 0.16px;
			font-size: 20px;
			font-weight: 100;
			margin-bottom: 15px;
		}

		.button {
			//background: mat.get-color-from-palette(palettes.$yellow-palette, 200);
			background: #ffeb00;
			width: 240px;
			box-shadow: 0 0 30px 0 #dae1f7;
			font-size: 14px;
			font-weight: 100;
			color: black;
		}
		.link-button-disabled {
			background-color: #ffeb00 !important;
			color: black !important;
		}
		.focused-input,
		.link.help:focus,
		input[type='radio']:focus + label,
		input[type='text']:focus {
			box-shadow: none;
		}
	}
}
