.quote-row-container {
	display: grid;
	grid-auto-flow: dense;
	grid-template-rows: repeat(1, 1fr);
	grid-template-columns: repeat(3, 1fr) 30px;
	padding-top: 10px;
}

.quote-row-item-title {
	font-weight: 700;
	font-size: 14px;
	color: #161616;
	margin-bottom: 0;
}

.quote-row-item-content {
	color: #161616;
	margin-bottom: 0;
}

#quote-row-accessorials {
	margin-bottom: 15px;
}

.quote-row-items {
	display: grid;
	grid-auto-flow: column;
	margin-bottom: 16px;
	grid-template-columns: 50px repeat(9, 1fr);
}

.quote-row-uplift-container {
	display: grid;
	grid-auto-flow: dense;
	grid-template-columns: 500px repeat(2, 1fr);
}

.quote-row-hr {
	margin-top: 10px;
}

.quote-row-circular-progress {
	justify-self: center;
	text-align: center;
	padding: 20px 0;
}

#quote-row-total-rates {
	padding-bottom: 20px;
}

.quote-row-total-customer-rate-with-insurance {
	font-weight: 400;
	font-size: 12px;
	color: #a1a1a1;
}

#quote-row-total-customer-rate-with-insurance-price {
	font-weight: 700;
}
