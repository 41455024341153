.new-quote-origin-container,
.new-quote-destination-container,
.new-quote-accesorials-container,
.new-quote-references-container {
	padding: 20px;
	background-color: #ffffff;
}

.new-quote-origin-button-same-accessorials {
	color: black !important;
	font-size: 0.8rem !important;
	text-transform: none !important;
	font-weight: 500 !important;
	float: right;
}

.new-quote-stepper-container {
	padding: 16px 20px;
	background: #f4f6f6;
	.new-quote-stepper-label {
		margin: 0;
	}
	.new-quote-stepper-body {
		display: block;
		margin-bottom: 16px;
	}
}

.new-quote-accesorials-container {
	a {
		font-family: 'Open Sans', sans-serif;
	}
}

.new-quote-origin-form-site-type-checks {
	display: flex;
}

.new-quote-origin-accesorials,
.new-quote-destination-accesorials,
.new-quote-general-accesorials {
	margin-bottom: 2em;
	margin-top: 1em;
	&-title {
		font-size: 14px;
	}
	&-chips {
		margin-top: 1em;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.MuiChip-root {
			margin-right: 1em;
			margin-bottom: 1em;
		}
	}
}

.new-quote-step-container-2 {
	width: 100%;
}

.new-quote-map-container {
	width: 50%;
}

.new-quote-sort {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px 20px 10px;
	&-select {
		width: 210px;
		min-width: 120px;
		margin: 2px;
	}
	&-info-section {
		display: flex;
		align-items: center;
		&-name {
			margin-left: 10px !important;
			font-weight: 700;
			font-size: 17px;
			color: #ec3514;
			font-style: normal;
		}
		&-icon {
			width: 32px;
			height: 32px;
			font-size: 8px;
			color: #ec3514;
			background-color: #f9d2ca;
			border-radius: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.new-quote-main-information {
	width: 45%;
	margin-left: 1.5em;
	&-header,
	&-body {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		.new-quote-info-item-special {
			margin: 0 0 12px 0;
			&-title {
				font-weight: 700;
			}
			width: 100%;
			border-bottom: 2px solid #c9cfe4;
		}
		.new-quote-info-item {
			width: 45%;
			margin: 0 0 12px 0;
			display: flex;
			flex-direction: column;
			&-title {
				font-weight: 700;
			}
			&-end {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
			}
		}
		.new-quote-info-company-name {
			font-family: 'Open Sans';
			font-weight: 700;
			font-size: 16px;
			color: rgb(236, 53, 20);
			text-transform: uppercase;
		}
		.new-quote-info-item-date {
			font-family: 'Open Sans';
			font-weight: 600;
			font-size: 12px;
			color: #a1a1a1;
			font-style: italic;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
	}
	.new-quote-main-information-container {
		display: flex;
		padding-bottom: 10px;
		border-bottom: 2px solid #c9cfe4;
		&-pickup,
		&-delivery {
			width: 50%;
			display: flex;
			flex-direction: column;
		}
		&-title {
			font-weight: 700;
		}
		&-site::first-letter {
			text-transform: uppercase;
		}
	}
}

.new-quote-main-information-body {
	border-bottom: none;
	margin: 2px 0 0 0;
}

.carousel-container {
	margin-left: 20px;
}

.new-quote-carrousel-item {
	width: auto !important;
}

.react-multiple-carousel__arrow {
	border-radius: 0 !important;
	height: 100% !important;
	width: 60px;
	background: #f4f6f6 !important;
	border: 1px solid #c9cfe4 !important;
	&::before {
		color: #c9cfe4 !important;
	}
}

button.react-multiple-carousel__arrow--right {
	right: 0;
}

button.react-multiple-carousel__arrow--left {
	left: 0;
}

.new-quote-carrier-information-card {
	background-color: white;
	padding: 10px;
	display: flex;
	border: 2px solid #c9cfe4;
	border-bottom: none;
	&:last-of-type {
		border-bottom: 2px solid #c9cfe4;
	}
	&-main {
		text-align: center;
		border-right: 2px solid #c9cfe4;
		padding: 10px;
		margin: 0;
		display: flex;
		&-service {
			display: flex;
			flex-direction: column;
			text-align: start;
			margin-left: 1em;
			min-width: 180px;
			span {
				font-weight: 600;
				font-size: 14px;
			}
			span.estimate {
				font-size: 11px;
				color: #a1a1a1;
				margin-right: 2em;
			}
			div {
				display: flex;
				align-items: center;
				span {
					font-weight: 600;
					font-size: 16px;
					i {
						font-size: 14px;
					}
				}
			}
		}
		&-logo-name {
			font-weight: 600;
			font-size: 14px;
			&-container {
				display: flex;
				align-items: center;
				&-left {
					display: flex;
					margin-right: 1em;
					margin-left: 1em;
					img {
						width: 50px;
						height: 50px;
						object-fit: contain;
					}
				}
				&-right {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					span {
						margin-bottom: 0.1em;
						font-weight: 600;
						font-size: 14px;
						margin-left: 0;
					}
					span.add {
						color: #ec3514;
						text-decoration: underline;
						margin-bottom: 1.5em;
					}
				}
			}
		}
	}
	&-maximum-carrier {
		padding-left: 20px;
	}
	&-rate {
		margin: 0;
		display: flex;
		flex-direction: column;
		text-align: center;
	}
	&-rate,
	&-customer-rate,
	&-with-insurance {
		// border-right: 2px solid #C9CFE4;
		margin: 0;
		display: flex;
		flex-direction: column;
		p {
			width: 101px;
			font-weight: 600;
			font-size: 12px;
			color: #00c48c;
			background-color: #d5f2ea;
			padding: 2px;
			text-align: center;
			margin-top: 0px;
			margin-bottom: 2px;
			border-radius: 5px;
		}
		span {
			font-weight: 600;
			font-size: 14px;
		}
		span.without-insurance {
			font-size: 11px;
			color: #a1a1a1;
		}
		span.price {
			font-size: 16px;
		}
	}
	&-add-insurance,
	&-delete-insurance {
		background-color: transparent;
		border: none;
		outline: unset;
		color: #ec3514;
		text-decoration: underline;
		text-align: left;
		cursor: pointer;
	}
	&-actions {
		display: flex;
		flex-direction: column;
		margin: 0 1em;
		justify-content: space-evenly;
		align-items: flex-start;
		span {
			font-weight: 400;
			color: #ccd2d8;
			font-size: 14px;
		}
		span.convert-to-shipment {
			color: #ccd2d8;
		}
	}
}

.new-quote-carrousel {
	height: 75px;
	max-width: 80%;
	border: 2px solid #a1a1a1;
	margin-left: 20px;
	&-items {
		display: flex;
		&-item {
			width: 85px;
			height: 75px;
			display: flex;
			flex-direction: column;
			padding: 10px 5px;
			border-right: 1px solid #c9cfe4;
			border-bottom: 1px solid #c9cfe4;
			border-top: 1px solid #c9cfe4;
			border-left: 1px solid #c9cfe4;
			justify-content: space-around;
			align-items: center;
			background-color: #ebeef1;
			cursor: pointer;
			&:hover,
			&:active,
			&:focus {
				background: white;
			}
			img {
				width: 50px;
				height: 45px;
			}
			span {
				font-weight: 600;
			}
		}
		&-item-selected {
			background-color: white;
		}
	}
}

.convert-to-shipment-button {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	outline: unset;
	margin: 2px 0;
	cursor: pointer;
}

.create-quote-circular-progress {
	width: 90%;
	height: 90%;
	display: grid;
	position: fixed;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.2);
	opacity: 0.8;
	z-index: 998;
}
