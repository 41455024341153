.shipment-items-slider {
	@media (min-width: 768px) {
		display: none;
	}
}

.shipment-items-slider-container {
	max-width: 400px;
	border: 1px solid #c9cfe4;
	border-radius: 4px;
	padding: 15px;
	background-color: #fafbfc;
}

.shipment-items-slider-title {
	margin: 0 0 8px 0;
	font-weight: 500;
	font-size: 14px;
	span {
		font-weight: 700;
		font-size: 14px;
	}
}

#shipment-items-slider-title-id {
	margin: 0 0 16px 0;
}

.shipment-items-slider-dots {
	margin-top: 20px;
	position: unset;
}

.react-multi-carousel-dot--active button {
	background: #007a8a !important;
	border-color: #007a8a !important;
	width: 14px;
	height: 14px;
}

.shipment-items-slider-dots button {
	background: #ccd2d8;
	border-color: #ccd2d8;
}

.shipment-items-slider-dots button:focus {
	outline: none;
}

#shipment-items-slider-icons {
	margin-top: 10px;
}
