.notifications-card {
	&-container {
		display: grid;
		row-gap: 8px;
		@media (min-width: 768px) {
			display: none;
		}
	}
	&-card {
		border: 1px solid #c9cfe4;
		border-radius: 4px;
		padding: 15px;
		background-color: #fafbfc;
	}
	&-title {
		font-weight: 700;
		font-size: 16px;
		color: #2c2c2c;
		margin: 0 0 16px 0;
	}
	&-info {
		margin: 0 0 8px 0;
		font-weight: 400;
		font-size: 14px;

		span {
			font-weight: 700;
			font-size: 14px;
		}
	}
	&-hr {
		border: 1px solid #c9cfe4;
		margin: 10px 0;
	}
	&-row {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: repeat(2, 1fr);
	}
	&-icon-title {
		display: flex;
		column-gap: 5px;
		justify-content: flex-end;
		margin-top: 19px;
	}
	&-title-new-contact {
		margin: 0;
		font-weight: 400;
		font-size: 13px;
		color: #2c2c2c;
		align-self: center;
	}
	&-icon-title-delete {
		display: flex;
		justify-content: flex-end;
	}
	&-title-delete {
		margin: 0 4px 0 0;
		font-weight: 400;
		font-size: 12px;
		color: #a1a1a1;
		align-self: center;
	}
}
