.drag-and-drop {
	&-area-and-items {
		@media (min-width: 768px) {
			display: grid;
			grid-auto-flow: column;
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&-added-title {
		font-weight: 600;
		font-size: 15px;
		margin: 0 0 16px 0;

		@media (min-width: 768px) {
			margin-bottom: 6px;
		}
	}
	&-area {
		width: 340px;
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column nowrap;
		font-size: 13px;
		font-weight: 400;
		color: #2c2c2c;
		border: 1px solid #c9cfe4;
		border-radius: 12px;
		background-color: #f7f8f8;
		position: relative;
		justify-self: end;

		@media (min-width: 768px) {
			width: 626px;
			height: 130px;
			margin-top: 12px;
		}

		& p {
			margin: 8px;
		}
	}
	&-title-mobile {
		@media (min-width: 768px) {
			display: none;
		}
	}
	&-title-web {
		@media (max-width: 767px) {
			display: none;
		}
	}
	&-uploader {
		width: 100%;
		height: 100%;
		opacity: 0;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		cursor: pointer;
	}
	&-icon-title {
		display: grid;
		justify-content: end;
		margin-top: 11px;
	}
	&-upload-documents {
		display: flex;
		&:hover {
			cursor: pointer;
		}
	}
	&-title {
		margin: 0 0 0 6px;
		font-weight: 400;
		font-size: 13px;
		align-self: center;
	}
}

.documents-table-container {
	@media (max-width: 767px) {
		display: none;
	}
}
