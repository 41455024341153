.hazmat-modal-form {
	text-align: center;
	padding: 0 33px;
}

.hazmat-modal-row {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 26px;
	margin-bottom: 25px;

	@media (max-width: 767px) {
		grid-auto-flow: row;
		grid-template-columns: none;
		row-gap: 26px;
	}
}

.hazmat-modal-buttons-row {
	display: grid;
	grid-auto-flow: column;
	column-gap: 15px;

	@media (max-width: 767px) {
		grid-auto-flow: row;
		row-gap: 26px;
	}
}
