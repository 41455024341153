.add-remove-insurance-container {
	display: flex;
	flex-direction: column;
}

.add-remove-insurance-modal-form {
	text-align: center;
}

.add-remove-insurance-modal-container-check {
	display: flex;
	flex-direction: column;
	margin: 1em 4em;
}

.add-remove-insurance-modal-error {
	color: red;
}
