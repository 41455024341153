.stepFive {
	&-div {
		padding: 18px 16px 21px 16px;

		@media (min-width: 768px) {
			padding: 35px 24px 15px 24px;
		}
	}
	&-text-instructions {
		margin: 0;
	}
	&-title {
		color: #ec3514;
		font-weight: 700;
		font-size: 15px;
		line-height: 22px;
		margin: 5px 0;

		@media (min-width: 768px) {
			font-size: 17px;
		}
	}
	&-container-instructions {
		padding: 0 16px 0 48px;
		@media (min-width: 768px) {
			padding: 0 23px 0 61px;
		}
	}
	&-icon-title {
		display: flex;
		column-gap: 12px;
		margin-bottom: 15px;

		@media (min-width: 768px) {
			margin-bottom: 4px;
		}
	}
}

#stepFive-instructions-step-4 {
	margin: 0;
}

#stepFive-icon-title-download-bol {
	margin-top: 16px;

	@media (min-width: 768px) {
		margin-left: 30px;
		margin-top: 0;
	}
}

#stepFive-notifications-table-title {
	margin-bottom: 35px;
}

#stepFive-documents-title {
	margin-bottom: 28px;
}

#stepFive-div-documents {
	padding-bottom: 0;
}
