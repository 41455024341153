.shipper-accesorials {
	margin: 2px 0;
	span {
		font-weight: 700;
	}
}

.shipper-main-div {
	@media (min-width: 768px) {
		padding: 0 16px;
	}
}

.shipper-container {
	display: grid;

	span {
		font-weight: 700;
	}

	p {
		margin: 2px 0;
		font-weight: 400;
	}

	&-cell {
		display: flex;
		flex-direction: column;
	}
}

.comment-section {
	margin: 2px 0;
	font-weight: 400;
}
