.notifications-table {
	&-icon-title {
		display: grid;
		justify-content: end;
		margin-top: 27px;
	}
	&-title {
		margin: 0 0 0 8px;
		font-weight: 400;
		font-size: 16px;
		align-self: center;
	}
	&-new-contact {
		display: flex;
		&:hover {
			cursor: pointer;
		}
	}
}
