.documents-card {
	&-container {
		display: grid;
		row-gap: 8px;
		margin-bottom: 16px;
		@media (min-width: 768px) {
			display: none;
		}
	}
	&-card {
		border: 1px solid #c9cfe4;
		border-radius: 4px;
		padding: 15px;
		background-color: #fafbfc;
	}
	&-title {
		font-weight: 700;
		font-size: 16px;
		color: #2c2c2c;
		margin: 0 0 16px 0;
	}
	&-info {
		margin: 0 0 8px 0;
		font-weight: 400;
		font-size: 14px;
		&-container {
			display: 'flex';
			column-gap: '5em';
			@media (min-width: 768px) {
				flex-direction: column;
			}
		}
		span {
			font-weight: 700;
			font-size: 14px;
		}
	}
}
