.table-toolbar-div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.table-toolbar-container {
	display: flex;
	align-items: center;
	font-size: 13px;
	margin: 19px 0 8px 0;

	@media (min-width: 1024px) {
		margin: 17px 0 14px 0;
	}
}

.table-toolbar-item-icon {
	display: flex;
	align-items: center;
	margin-left: 24px;
	cursor: pointer;
}

.table-toolbar-selected {
	text-align: start;
	margin: 0;
}

.table-toolbar-item-title {
	margin: 0;
}

.table-toolbar-filterIcon {
	cursor: pointer;
}

@media only screen and (min-width: 415px) {
	.table-toolbar-selected {
		font-size: 16px;
	}
}
