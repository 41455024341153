.app-tab {
	display: flex;
	column-gap: 15px;
	flex-direction: column;
	align-items: flex-start;
	text-align: initial;
	&-locations {
		display: flex;
	}
	&-bold {
		margin: 0;
	}
	span {
		font-weight: 400;
		margin-left: 5px;
	}
	&-origin {
		margin: 2px 0;
	}
}

.multi-tabs-add-button::first-letter {
	font-size: 24px !important;
	color: #ec3514;
}

.hiddenClose {
	display: none !important;
}
