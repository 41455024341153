.swal2-container {
	z-index: 10000 !important;
}

.swal2-popup {
	padding: 1.25em 0;
	max-width: 360px;
}

.swal2-show {
	background: white !important;
	width: 500px !important;
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
	position: relative !important;
	border-radius: 5px !important;
	border-bottom: 6px solid #007a8a !important;
	z-index: 4 !important;
}

.swal2-header {
	padding: 0;
	width: 100%;
}

.swal2-title {
	font-family: 'Roboto', sans-serif !important;
	font-size: 1.5em !important;
	color: #007a8a !important;
	font-weight: bold !important;
	line-height: 35px !important;
	justify-content: center;
	width: 100%;
	.custom-title {
		border-bottom: 1px solid #ccd2d8;
		display: flex;
		margin: 0;
		padding: 0 16px 14px;
		width: 100%;
		span {
			color: #c6c6c6;
			font-size: 20px;
			font-weight: 600;
		}
	}
	.icon-title {
		background-image: url('../../assets/img/deleteIcon.png');
		background-repeat: no-repeat;
		background-size: contain;
		display: block;
		height: 32px;
		margin-right: 12px;
		width: 32px;
	}
}

.swal2-content {
	width: 100%;
}

.swal2-html-container {
	text-align: left;
}

.swal2-icon.swal2-warning {
	border-color: #cf700a !important;
	color: #cf700a !important;
}

.swal2-styled.swal2-confirm {
	background-color: #007a8a !important;
	font-family: 'Roboto', sans-serif !important;
	color: white !important;
	border: none !important;
	border-radius: 5px !important;
	font-size: 16px !important;
	cursor: pointer !important;
}

.swal2-close {
	color: black !important;
}

.swal2-icon.swal2-error .swal2-x-mark {
	position: static !important;
}

.swal2-actions {
	button {
		display: block;
		width: 100%;
	}
}

span {
	margin-left: 0 !important;
}
