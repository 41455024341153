.stepTwo {
	&-container {
		display: grid;
		grid-auto-flow: row;
		row-gap: 16px;
		padding: 18px 16px 20px 16px;
		background: #ffffff;

		@media (min-width: 768px) {
			grid-auto-flow: column;
			grid-template-columns: repeat(2, 1fr);
			padding: 20px 24px;
		}
	}
	&-title {
		font-weight: 600;
		color: #090e24;
		font-size: 22px;
		line-height: 22px;
		margin: 10px 0;
	}
	&-text {
		font-size: 13px;
		margin: 0;
	}

	&-comments {
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		span {
			font-weight: 700;
			font-size: 14px;
		}

		p {
			margin: 5px 0;
			font-weight: 500;
			font-size: 14px;
		}
	}
	&-icon-title {
		display: flex;
		column-gap: 12px;
		margin-bottom: 15px;
	}
	&-icon {
		width: 20px;
		height: 20px;
		background-color: #f9d2ca;
		border-radius: 20px;
		padding: 4px;
		align-self: center;

		@media (min-width: 768px) {
			width: 24px;
			height: 24px;
		}
	}
}

.new-shipment-items-container {
	@media (max-width: 767px) {
		display: none;
	}
}

.shipment-references-container {
	@media (max-width: 767px) {
		display: none;
	}
}

#stepTwo-container-references-instructions {
	@media (max-width: 767px) {
		background: linear-gradient(180deg, white 50%, #f7f8f8 50%);
	}
}
