// .new-shipment-container {
//   @media (min-width: 600px) and (max-width: 1337px) {
//     width: 90%;
//   }
// }

.new-shipment-title {
	text-align: start;
	margin: 0;
	font-family: 'Open Sans';
	font-size: 34px;
	font-weight: 700;
	color: #2c2c2c;
}

.new-shipment-shipment-information {
	padding: 20px;
}

.new-shipment-item-container {
	margin-top: 25px;
}

.new-shipment-icon-title {
	display: flex;
	column-gap: 12px;
	margin-bottom: 32px;
}

.new-shipment-item-title {
	font-family: 'Open Sans';
	font-size: 17px;
	font-weight: 700;
	color: #2c2c2c;
	margin: 0;
}

.new-shipment-inputs {
	display: grid;
	grid-auto-flow: dense;
	margin-bottom: 25px;
	column-gap: 26px;
}

#new-shipment-inputs-row-1 {
	grid-template-columns: repeat(4, 1fr);
}

#new-shipment-inputs-row-2 {
	grid-template-columns: repeat(1, 1fr) 120px 120px repeat(2, 1fr);

	@media (min-width: 1920px) {
		grid-template-columns: repeat(1, 1fr) 180px 180px repeat(2, 1fr);
	}
}

#new-shipment-inputs-row-3 {
	grid-template-columns: 560px repeat(2, 1fr);

	@media (min-width: 1920px) {
		grid-template-columns: 800px repeat(2, 1fr);
	}
}

#new-shipment-inputs-row-3-consignee {
	grid-template-columns: 265px 265px repeat(1, 1fr);

	@media (min-width: 1920px) {
		grid-template-columns: 385px 385px repeat(1, 1fr);
	}
}

.new-shipment-country-flag {
	width: 20px;
	margin-right: 16px;
}

.new-shipment-items-inputs-row-1 {
	display: grid;
	grid-auto-flow: dense;
	grid-template-columns: 14% 9% 14% 11% 10% 10% 10% 10%;
	column-gap: 20px;
}

.new-shipment-items-inputs-row-2 {
	display: grid;
	grid-auto-flow: dense;
	grid-template-columns: 30% 23% 23% 8% 8%;
	column-gap: 17px;
}

.new-shipment-buttons {
	display: grid;
	grid-auto-flow: column;
	justify-content: flex-end;
	padding: 20px;
	grid-template-areas: 'cancel-button previous-step-button book-now-button';

	@media (max-width: 767px) {
		width: 100%;
		display: grid;
		grid-auto-flow: row;
		justify-content: unset;
		justify-items: center;
		row-gap: 16px;
		margin-top: 40px;
		padding: 0 0 30px 0;
		grid-template-areas:
			'book-now-button'
			'previous-step-button'
			'cancel-button';
	}
}

#new-shipment-cancel-button {
	grid-area: cancel-button;

	@media (max-width: 767px) {
		width: 90%;
	}
}

#new-shipment-previous-step-button {
	grid-area: previous-step-button;
	margin-left: 1em;

	@media (max-width: 767px) {
		width: 90%;
		margin-left: 0;
	}
}

#new-shipment-book-now-button {
	grid-area: book-now-button;
	margin-left: 1em;

	@media (max-width: 767px) {
		width: 90%;
		margin-left: 0;
	}
}

.stepTwo-shipment-items-icons-container {
	display: flex;
	flex-direction: row;
	column-gap: 10px;
}

.stepTwo-shipment-items-icon {
	width: 24px;
}

.stepTwo-total-values {
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	margin-top: 10px;

	@media (min-width: 768px) {
		flex-direction: row;
		column-gap: 60px;
		margin-top: 0;
	}
}

.stepTwo-total-title {
	font-size: 14px;
	font-weight: 700;
	color: #a1a1a1;
	margin: 10px 0 0 0;

	@media (min-width: 768px) {
		margin: 10px 0 5px 0;
		font-weight: 400;
		color: #2c2c2c;
	}

	span {
		font-size: 14px;
		color: #a1a1a1;
		font-weight: 400;

		@media (min-width: 768px) {
			font-weight: 700;
			color: #2c2c2c;
		}
	}
}

.new-shipment-buttons-third-step {
	display: grid;
	grid-auto-flow: column;
	justify-content: flex-end;
	grid-template-areas: 'new-quote shipment-list';

	@media (max-width: 767px) {
		width: 100%;
		display: grid;
		grid-auto-flow: row;
		justify-content: unset;
		justify-items: center;
		padding: 0;
		grid-template-areas:
			'shipment-list'
			'new-quote';
	}
}

#new-shipment-new-quote-button {
	grid-area: new-quote;

	@media (max-width: 767px) {
		width: 90%;
		margin-top: 16px;
	}
}

#new-shipment-shipment-list-button {
	grid-area: shipment-list;
	margin-left: 24px;

	@media (max-width: 767px) {
		width: 90%;
		margin-left: 0;
	}
}
